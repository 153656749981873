
<template>
  <div>
    <page-header title='Avaliação dos alunos'></page-header>

    <v-card style="border-radius:12px" flat outlined>
      <v-card-title primary-title class='primary white--text py-1' >
        <v-row class="px-3 py-4 pb-5" justify="space-between">
          <v-col cols='12' md='9' class="pa-0">
            <div class="text-subtitle-2">Curso</div>
            <div style="word-break: break-word">{{course.name}}</div>
          </v-col>
          <v-col cols='12' md='3' class="pa-0" align="right">
            <div class="text-subtitle-2 d-flex justify-end">
              Status: {{course_status_map[course.status]}}
              <course-details-dialog class="ml-2" :course="course"></course-details-dialog>
              <!-- <v-btn fab x-small class="elevation-0" max-width='20' max-height='20' color="white" @click="showCourseDetails()"><v-icon>mdi-information-variant</v-icon></v-btn> -->
            </div>
            <v-btn v-if="['in_progress', 'done'].includes(course.status)" color="success" small @click="$router.push({name: 'Select'})" rounded>
              <v-icon left>mdi-keyboard-return</v-icon> Ver inscrições  
            </v-btn>
            <v-btn class="ml-2" v-if="['in_progress'].includes(course.status)" color="success" small @click="changeCourseStatus()" rounded :disabled="!are_all_graded">
              Concluir Curso  
              <v-icon right>mdi-check-all</v-icon>
            </v-btn>
            <v-btn color="success" class="mx-2" small rounded 
                v-if="['done'].includes(course.status)" 
                :to="({name: 'CourseRatings', params: {id: course.id}})"
              >Ver avaliações do curso</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <card-loading-transparent v-if="loading"></card-loading-transparent>
      <v-card-text class="px-0 pb-3" v-if="!loading">

        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items-per-page="10"
          :items="enrolleds"
          :search="search"
        >

        <template v-slot:top>
          <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
        </template>
        <template v-slot:[`item.actions`]="{ item, index }">
          <v-btn small text color="primary" @click="openDialog(item, index)" rounded outlined>
            <v-icon left>mdi-clipboard-text</v-icon>{{course.status == 'in_progress'? 'Avaliar aluno' : 'Ver avaliação'}}
          </v-btn>
        </template>

        <template v-slot:[`item.had_graded`]="{ item }">
          <v-chip small :color="item.had_graded ? 'success' : 'primary'">{{item.had_graded ? 'Avaliado' : 'Não avaliado'}}</v-chip>
        </template>

        <template v-slot:[`item.birthdate`]="{ item }">
          {{ $dayjs().diff($dayjs(item.birthdate).format('YYYY-MM-DD'), 'year' )}} anos
        </template>

        <template v-slot:[`item.country`]="{ item }">
          {{ countries_map[item.country] != null ? countries_map[item.country] : item.country }}
        </template>
      
        </v-data-table>
      </v-card-text>
      
    </v-card>

    <dialog-student-rating 
      @closeDialog="handleCloseDialog"
      @doneUpdate="handleDoneUpdate"
      :dialog="dialog"
      :student_enrolled="student_enrolled"
      v-if="dialog"
      :max_grade="max_grade"
      :can_be_graded="course.status=='in_progress'"
    />
    
  </div>
</template>

<script>

import Api from '@/api/index'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'StudentRatingIndex',
  components: {
    DialogStudentRating: () => import ('./DialogStudentRating'),
    CourseDetailsDialog: () => import ('../shared/CourseDetailsDialog.vue'),
  },
  data() {
    return {
      search: '',
      dialog: false,
      loading: false,

      course_status_map:{
        active: 'Ativo', 
        selecting: 'Selecionando', 
        done_selection: 'Seleção Pronta', 
        in_progress: 'Em Progresso', 
        done: 'Conluido', 
        suspended: 'cancelado'
      },

      headers: [
        {text: '#', value: 'id'},
        {text: 'Aluno', value: 'name'},
        { text: 'E-mail', value: 'email' },
        // { text: 'Estado', value: 'state' },
        // { text: 'País', value: 'country' },
        // { text: 'Idade', value: 'birthdate' },
        { text: 'Nota', value: 'grade' },
        { text: 'Status', value: 'had_graded', align: 'center'},
        { text: 'Avaliar', value: 'actions', align: 'center', sortable: false},
      ],

      countries_map:{ 
        brazilian: 'Brasileiro', 
        argentine: 'Argentina', 
        colombian: 'Colombia', 
        uruguayan: 'Uruguai', 
        paraguayan: 'Paraguai', 
      },

      course: {},
      max_grade: null,
      enrolleds: [],
      student_enrolled: {},
      item_idx_aux: null
    }
  },
  methods: {
    handleCloseDialog(){
      this.dialog = false
    },
    handleDoneUpdate(data){
      this.dialog = false
      if(data.response_status == 200){
        data.enrolled.had_graded = true
        this.enrolleds.splice(this.item_idx_aux, 1, data.enrolled)
      }
    },
    changeCourseStatus(){      
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed){
          let course = {
            id: this.course.id,
            status: 'done'
          }
          this.updateCourseStatus(course)
          this.getInfos()
        }
      })
    },
    updateCourseStatus(course){
      Api.Course.update(course).then(()=>{
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
        this.course.status = course.status
      }).catch(err => {
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    openDialog(item, idx){
      this.item_idx_aux = idx
      this.student_enrolled = item
      this.dialog = true
    },
    getCourseInfo(){
      Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=>{
        this.course = {...d}
        this.max_grade = d.max_grade
      }).catch((err) =>{
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    getEnrolleds(){
      Api.Enrolled.index({course_id: this.$route.params.id}).then(r=>r.data).then((d)=>{
        this.enrolleds = d.filter(e=>e.status == 'confirmed')
      }).catch((err) =>{
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    getInfos(){
      this.getCourseInfo();
      this.getEnrolleds()
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  mounted () {
    this.getInfos()
  },
  computed: {
    are_all_graded(){
      if (this.enrolleds.length && this.course.id){
        return this.enrolleds.filter(e=>e.status == 'confirmed').map(e=> e.grade !=null && e.had_graded).every(e=> !!e)
      }
      return false
    },
  },
}
</script>

<style scoped>

</style>